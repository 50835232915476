import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { theme } from '../styles/theme';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div
      css={[
        theme.components.row,
        {
          paddingTop: '2rem',
        },
      ]}
      className="theme">
      <h1 css={[theme.components.title, theme.components.titleLarge]}>
        NOT FOUND
      </h1>
      <p>
        Sorry, we couldn't find that page. Please visit the{' '}
        <Link to="/">homepage</Link>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
